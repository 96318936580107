import { Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Eye, EyeSlash } from "react-bootstrap-icons";

import { setCredentials } from "../slices/authSlice";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { useAssociateLoginMutation } from "../slices/associatesSlice";
import { getCurrentDate, getCurrentTime } from "../utils/getCurrentDate";

const LoginScreen = () => {
  const [userId, setUserId] = useState();
  const [password, setPassword] = useState("");
  const [pwdVisible, setPwdVisible] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, { isLoading }] = useAssociateLoginMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const { search } = useLocation();
  const p = new URLSearchParams(search);
  const redirect = p.get("redirect") || "/";

  // const handleScan = () => {
  //   navigate("/qrscanner");
  // };

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [userInfo, navigate, redirect]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (userId?.length !== 8 || Number(userId) < 0)
      toast.warning("Please provide valid user Id");
    else if (password?.length < 8 || password?.length > 50)
      toast.error("User Id or Password is incorrect");
    else
      try {
        const res = await login({
          gymId: userId?.substring(0, 4),
          associateId: userId?.substring(4, 8),
          password: password,
        }).unwrap();
        dispatch(
          setCredentials({
            ...res,
            lastVisitedPage: "/",
            loginDate: getCurrentDate() + getCurrentTime(),
            audioOn: true,
            visitorsStartDate: getCurrentDate(),
            visitorsEndDate: getCurrentDate(),
            attendancesStartDate: getCurrentDate(),
            attendancesEndDate: getCurrentDate(),
            paymentsStartDate: getCurrentDate(),
            paymentsEndDate: getCurrentDate(),
            accountsStartDate: getCurrentDate(),
            accountsEndDate: getCurrentDate(),
            dashboardStartDate: getCurrentDate(),
            dashboardEndDate: getCurrentDate(),
            attendancesStartDateOfUser: getCurrentDate(),
            attendancesEndDateOfUser: getCurrentDate(),
          })
        );
        // navigate(redirect);
        navigate("/");
      } catch (err) {
        toast.error(err?.data?.error || err.error);
      }
  };

  const setPwdVisibility = () => {
    setPwdVisible(!pwdVisible);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "90vh",
      }}
    >
      <FormContainer>
        <Form onSubmit={submitHandler}>
          <Form.Group className="my-2" controlId="gymId">
            <Form.Label id="username">Id : </Form.Label>
            <Form.Control
              type="text"
              pattern="[0-9]*" // Allow only digits
              value={userId || ""}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                setUserId(value);
              }}
              placeholder="user ID"
            />
          </Form.Group>

          <Form.Group className="my-2" controlId="password">
            <Form.Label id="password">Password : </Form.Label>
            <div style={{ display: "flex" }}>
              <InputGroup>
                {" "}
                <Form.Control
                  type={pwdVisible ? "text" : "password"}
                  placeholder="enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button onClick={setPwdVisibility}>
                  {pwdVisible ? <Eye /> : <EyeSlash />}
                </Button>{" "}
              </InputGroup>
            </div>
          </Form.Group>
          <Button type="submit" variant="primary">
            Sign In
          </Button>

          {isLoading && <Loader />}
        </Form>
        <Row className="py-3">
          <Col>
            new user ? <Link to="/register">Register</Link>
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <Link to="/resetPassword">Forgot Password ?</Link>
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <a
              href="https://zpottergym.s3.ap-south-1.amazonaws.com/zpotter_App/zpotter-android-app.apk"
              download
            >
              Download Android App
            </a>
          </Col>
        </Row>
        <br></br>
        <br></br>
        <br></br> <br></br>
        <div style={{ textAlign: "center", justifyContent: "center" }}>
          {" "}
          <h6>
            Incase of any query, Please contact us in the below email or mobile
            numbers
          </h6>
          <h6>
            Email :{" "}
            <label style={{ color: "blue" }}>contactus@zpotter.in</label>
          </h6>
          <h6>
            WhatsApp or Call :{" "}
            <label style={{ color: "blue" }}>7806873973, 9092913756</label>
          </h6>{" "}
          <Link to="/privacyPolicy">Privacy Policy</Link>
        </div>
        {/* <Button onClick={handleScan}>Scan QR Code</Button> */}
      </FormContainer>
    </div>
  );
};

export default LoginScreen;
