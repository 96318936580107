import { Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Eye, EyeSlash } from "react-bootstrap-icons";

import { setCredentials } from "../slices/authSlice";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { useAssociateLoginMutation } from "../slices/associatesSlice";
import { getCurrentDate, getCurrentTime } from "../utils/getCurrentDate";

const ContactUsScreen = () => {
  return (
    <div style={{ textAlign: "center", justifyContent: "center" }}>
      {" "}
      <h5>
        Incase of Any issues or query, Please contact us in the below email or
        mobile numbers
      </h5>
      <h2>
        Email : <label style={{ color: "blue" }}>contactus@zpotter.in</label>{" "}
        <br></br>
        <label style={{ color: "blue" }}>zpottertechnologies@gmail.com</label>
      </h2>
      <br></br>
      <h3 style={{ color: "green" }}>WhatsApp or Call</h3>{" "}
      <h2>
        Mobile 1 : 7806873973<br></br>
      </h2>
      <h2>Mobile 2 : 9092913756</h2>
    </div>
  );
};

export default ContactUsScreen;
