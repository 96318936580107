import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Table, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useGetAttendanceQuery } from "../slices/attendanceSlice";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { getCurrentDate } from "../utils/getCurrentDate";
import { getCurrentTime } from "../utils/getCurrentDate";
import { setCredentials } from "../slices/authSlice";

const AttendancesOfUserScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { id: userId } = useParams();
  const [startDate, setStartDate] = useState(
    userInfo.attendancesStartDateOfUser
  );

  const [endDate, setEndDate] = useState(userInfo.attendancesEndDateOfUser);

  const [getApiQuery, setGetApiQuery] = useState(
    `?gymId=${userInfo.gymId._id}&userId=${userId}&attendanceDate[gte]=${startDate}&attendanceDate[lte]=${endDate}&fields=userId,timeIn,timeOut,attendanceDate&sort=-attendanceDate`
  );

  const {
    data: attendances,
    isLoading,
    error,
    refetch,
  } = useGetAttendanceQuery({
    query: getApiQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const dispatch = useDispatch();

  const handleGetUsers = async () => {
    if (startDate > getCurrentDate())
      toast.error("Start date can not be greater than today");
    else if (startDate > endDate)
      toast.error("Start date can not be greater than End date");
    else {
      if (endDate?.split("T")[0] > getCurrentDate())
        setEndDate(getCurrentDate() + getCurrentTime());
      setGetApiQuery(
        `?gymId=${userInfo.gymId._id}&userId=${userId}&attendanceDate[gte]=${startDate}&attendanceDate[lte]=${endDate}&fields=userId,timeIn,timeOut,attendanceDate&sort=-attendanceDate`
      );
      dispatch(
        setCredentials({
          ...userInfo,
          attendancesStartDateOfUser: startDate,
          attendancesEndDateOfUser: endDate,
        })
      );
    }
  };

  const [initApiRequestAtIntervals, setInitApiRequestAtIntervals] = useState(1);
  setInterval(() => {
    setInitApiRequestAtIntervals(Date.now());
  }, 1200000);

  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate("/attendanceList");
  };

  useEffect(() => {
    refetch();
  }, [refetch, initApiRequestAtIntervals]);

  return (
    <>
      <Button style={{ backgroundColor: "grey" }} onClick={handleBackButton}>
        Back
      </Button>
      <h3>Attendance for : {userId}</h3>
      <Row
        className="mb-3"
        style={{
          justifyContent: "center",
          margin: "auto",
          width: "95%",
          alignItems: "center",
        }}
      >
        <Col xs={12} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-23"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            ></Form.Control>{" "}
          </Form.Group>
        </Col>
        <Col xs={12} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-27"
              value={endDate?.split("T")[0]}
              onChange={(e) => setEndDate(e.target.value)}
            ></Form.Control>{" "}
          </Form.Group>
        </Col>
        <Col xs={12} md="auto" sm={6}>
          <Form.Group as={Row} className="my-2" controlId="gymType">
            <Button onClick={handleGetUsers}>Get Data</Button>
          </Form.Group>
        </Col>
      </Row>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : attendances ? (
        <div>
          <Table striped bordered hover responsive="sm">
            <thead>
              <tr>
                <th>Date</th>
                <th> In</th>
                <th> Out</th>
              </tr>
            </thead>
            <tbody>
              {attendances.map((user) => (
                <tr>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {user.attendanceDate?.split("T")[0]}
                  </td>
                  <td>
                    {" "}
                    {new Date(user.timeIn).getHours() +
                      ":" +
                      (new Date(user.timeIn).getMinutes() < 10
                        ? "0" + new Date(user.timeIn).getMinutes()
                        : new Date(user.timeIn).getMinutes())}
                  </td>
                  <td style={{ borderBottomRightRadius: "30%" }}>
                    {" "}
                    {user.timeOut
                      ? new Date(user.timeOut).getHours() +
                        ":" +
                        (new Date(user.timeOut).getMinutes() < 10
                          ? "0" + new Date(user.timeOut).getMinutes()
                          : new Date(user.timeOut).getMinutes())
                      : "yet to mark"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AttendancesOfUserScreen;
