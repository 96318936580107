import React, { useEffect, useState, useRef } from "react";
import { Row, Button, Form, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import Chart from "chart.js/auto";
import { useNavigate, useParams } from "react-router-dom";
import { CategoryScale } from "chart.js";
import LineChart from "../components/LineChart";
import Loader from "../components/Loader";
import {
  useGetAttendanceForUsersQuery,
  useGetAttendanceQuery,
} from "../slices/attendanceSlice";

import Message from "../components/Message";
import FormContainer from "../components/FormContainer";
import { getDateOfLast } from "../utils/getCurrentDate";

Chart.register(CategoryScale);

const NumberForUsersAppScreen = () => {
  const [gymUid, setGymUid] = useState();

  const [startDate, setStartDate] = useState(getDateOfLast(15));

  const [getApiQuery, setGetApiQuery] = useState(
    `?gymId=${gymUid}&attendanceDate[gte]=${startDate}`
  );
  const [currentUsersAtGym, setCurrentUsersAtGym] = useState(0);

  const {
    data: attendanceList,
    isLoading,
    refetch,
    error,
  } = useGetAttendanceForUsersQuery({
    query: getApiQuery,
  });

  const chartLabels = [
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
  ];

  const getDataForChart = async (attendanceOnGivenDuration) => {
    attendanceOnGivenDuration = attendanceOnGivenDuration?.map((att) =>
      new Date(att.timeIn).getHours()
    );

    let countUsersPerHour = {};
    attendanceOnGivenDuration?.forEach((element) => {
      countUsersPerHour[element] = (countUsersPerHour[element] || 0) + 1;
    });

    let dataForChart = chartLabels?.map((l) =>
      countUsersPerHour[l] ? countUsersPerHour[l] : 0
    );
    return dataForChart;
  };

  const getAttendanceForPeriod = async () => {
    let attendanceData = isLoading ? [] : attendanceList;
    return attendanceData;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!isLoading && attendanceList) {
        const currentUsers = attendanceList.filter((att) => {
          return att.timeIn >= Date.now() - 3600000 && !att.timeOut;
        });
        setCurrentUsersAtGym(currentUsers);

        let attendanceOnGivenPeriod = await getAttendanceForPeriod();
        let dataForChartForPeriod = await getDataForChart(
          attendanceOnGivenPeriod
        );
        let chartDataForPeriod = {
          labels: chartLabels,
          datasets: [
            {
              data: dataForChartForPeriod,
              fill: true,
              borderColor: "rgb(0, 200, 40)",
            },
          ],
        };
        setChartObjectForPeriod(chartDataForPeriod);
      }
    };

    fetchData();
  }, [attendanceList, isLoading]);

  const [chartObjectForPeriod, setChartObjectForPeriod] = useState({
    labels: chartLabels,
    datasets: [
      {
        data: [0, 0, 0, 0, 0],
        fill: true,
        borderColor: "rgb(100, 230, 100)",
      },
    ],
  });

  const loadHourlyData = async () => {
    setGetApiQuery(`?gymId=${gymUid}&attendanceDate[gte]=${startDate}`);
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <FormContainer>
          <Form.Group className="my-2" controlId="userId">
            <Form.Label id="userId">Enter Code</Form.Label>
            <Form.Control
              type="text"
              pattern="[0-9]*" // Allow only digits
              placeholder="enter Id"
              autoComplete="off"
              autoFocus
              value={gymUid}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                setGymUid(value);
              }}
            />
          </Form.Group>

          <Button type="submit" variant="primary" onClick={loadHourlyData}>
            Submit
          </Button>
          {isLoading && <Loader />}
        </FormContainer>
      </div>

      {error ? (
        <Message>{error?.data?.error}</Message>
      ) : (
        <div>
          <Row>
            <Button className=" buttonGreen" style={{ marginTop: "3%" }}>
              People at Gym : {currentUsersAtGym?.length}
            </Button>
          </Row>
          <Row>
            <LineChart
              chartData={{
                ...chartObjectForPeriod,
                labelText: `X-axis represents hours, Y-axis represents total number of users came to gym on that hour... Average crowd at every hour in the last 15 days`,
              }}
            />
          </Row>
        </div>
      )}
    </>
  );
};
export default NumberForUsersAppScreen;
