import { Button, Form, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Eye, EyeSlash } from "react-bootstrap-icons";

import { useChangePasswordMutation } from "../slices/associatesSlice";
import RegistrationContainer from "../components/RegistrationContainer";

const ChangePasswordScreen = ({ user, changePassword }) => {
  const [associateId, setAssociateId] = useState(user.associateId);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [currentPwdVisible, setCurrentPwdVisible] = useState(false);
  const [newPwdVisible, setNewPwdVisible] = useState(false);
  const [confirmPwdVisible, setConfirmPwdVisible] = useState(false);

  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);
  const [changeUserPassword, { isLoading }] = useChangePasswordMutation();

  // let { search } = useLocation();
  // const ps = new URLSearchParams(search);
  // const redirect = ps.get("redirect") || "/";

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await changeUserPassword({
        associateId,
        payload: { password, newPassword, confirmNewPassword },
        token: userInfo.token,
      }).unwrap();
      navigate("/profile");
      changePassword();
      toast.success("Password Changed Successfully");
    } catch (e) {
      toast.error(e.data?.error);
    }
  };

  const handleCancelButton = () => {
    navigate("/profile");
    changePassword();
  };

  const setCurrentPwdVisibility = () => {
    setCurrentPwdVisible(!currentPwdVisible);
  };
  const setNewPwdVisibility = () => {
    setNewPwdVisible(!newPwdVisible);
  };
  const setConfirmPwdVisibility = () => {
    setConfirmPwdVisible(!confirmPwdVisible);
  };

  return (
    <>
      <RegistrationContainer>
        <h5> Change Password</h5>
        <Form onSubmit={submitHandler}>
          <Row>
            {" "}
            <Form.Group as={Row} className="my-2" controlId="password">
              <Form.Label column="true" sm="4">
                Current Password :
              </Form.Label>
              <Col sm="5">
                <div style={{ display: "flex" }}>
                  <Form.Control
                    type={currentPwdVisible ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="enter current password"
                  ></Form.Control>{" "}
                  <Button onClick={setCurrentPwdVisibility}>
                    {currentPwdVisible ? <Eye /> : <EyeSlash />}
                  </Button>
                </div>
              </Col>{" "}
            </Form.Group>
            <Form.Group as={Row} className="my-2" controlId="newPassword">
              <Form.Label column="true" sm="4">
                New Password :
              </Form.Label>{" "}
              <Col sm="5">
                {" "}
                <div style={{ display: "flex" }}>
                  <Form.Control
                    type={newPwdVisible ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="enter New password"
                  ></Form.Control>
                  <Button onClick={setNewPwdVisibility}>
                    {newPwdVisible ? <Eye /> : <EyeSlash />}
                  </Button>{" "}
                </div>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="my-2"
              controlId="confirmNewPassword"
            >
              <Form.Label column="true" sm="4">
                Confirm New Password :
              </Form.Label>
              <Col sm="5">
                <div style={{ display: "flex" }}>
                  <Form.Control
                    type={confirmPwdVisible ? "text" : "password"}
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    placeholder="enter New password"
                  ></Form.Control>{" "}
                  <Button onClick={setConfirmPwdVisibility}>
                    {confirmPwdVisible ? <Eye /> : <EyeSlash />}
                  </Button>{" "}
                </div>
              </Col>
            </Form.Group>
            <Row column="true" sm="6">
              <Col sm="4"></Col>
              <Col sm="5">
                <Button type="submit" disabled={isLoading} variant="primary">
                  {" "}
                  Submit
                </Button>{" "}
                <Button
                  style={{ marginLeft: "15%" }}
                  type="button"
                  disabled={isLoading}
                  variant="secondary"
                  onClick={handleCancelButton}
                >
                  {" "}
                  Cancel
                </Button>
              </Col>
            </Row>
          </Row>
        </Form>
      </RegistrationContainer>
    </>
  );
};

export default ChangePasswordScreen;
