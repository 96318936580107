import React, { useState, useEffect } from "react";
import { Row, Col, ListGroup, Card, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import ChangePasswordScreen from "./ChangePasswordScreen";
import MyProfileEditScreen from "./MyProfileEditScreen";
import { useGetMyDetailsQuery } from "../slices/associatesSlice";
import { setCredentials } from "../slices/authSlice";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {
  useGetMyGymQuery,
  useUpdateUsersTokenMutation,
} from "../slices/gymSlice";
import VerticallyCenteredModal from "../components/SubmitModal";
import { toast } from "react-toastify";
import GymProfileEditScreen from "./GymProfileEditScreen";
import GymPaymentUpdateScreen from "./GymPaymentUpdateScreen";

const GymProfileScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);

  const [editable, setEditable] = useState(false);
  const [updatePayment, setUpdatePayment] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const [updateGym] = useUpdateUsersTokenMutation();

  let {
    data: myGymDetails,
    isLoading,
    error,
    refetch,
  } = useGetMyGymQuery({
    token: userInfo.token,
  });

  console.log(myGymDetails);

  useEffect(() => {
    refetch();
  }, [refetch, editable, updatePayment]);

  const handleEditStatus = () => {
    setEditable(false);
  };

  const handleUpdatePayment = () => {
    setUpdatePayment(false);
  };

  const submitEdit = async () => {
    try {
      await updateGym({
        gymId: userInfo.gymId._id,
        token: userInfo.token,
      }).unwrap();

      toast.success("URL is updated");
    } catch (e) {
      toast.error(e?.data?.error || e.data);
    }
  };

  return (
    <>
      <VerticallyCenteredModal
        title="Are you sure want to Change URL ?"
        show={modalShow}
        size="sm"
        onHide={() => setModalShow(false)}
        onSubmit={submitEdit}
      />

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : (
        <>
          {" "}
          {editable ? (
            <GymProfileEditScreen
              associate={myGymDetails}
              editable={handleEditStatus}
            />
          ) : updatePayment ? (
            <GymPaymentUpdateScreen
              associate={myGymDetails}
              updatePayment={handleUpdatePayment}
            />
          ) : (
            <>
              <h4>Gym Profile : {myGymDetails?._id} </h4>
              <Row style={{ margin: "2%" }}>
                {userInfo.role === "owner" ||
                userInfo.role === "Super Admin" ? (
                  <Button
                    className=" marginPointEight sixteenWidth buttonPeach twoButtonInRow"
                    onClick={(e) => setEditable(true)}
                  >
                    Edit
                  </Button>
                ) : (
                  <></>
                )}

                <Button
                  className=" marginPointEight sixteenWidth buttonTeal twoButtonInRow"
                  onClick={(e) => setUpdatePayment(true)}
                >
                  Update Payment
                </Button>
              </Row>

              <Row>
                <Col md={5}>
                  <Card>
                    <ListGroup>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Gym Nme : </Col>
                            <Col> {myGymDetails.name}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Owner Name : </Col>
                          <Col> {myGymDetails.ownerName}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> email : </Col>
                          <Col> {myGymDetails.email}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> gymType : </Col>
                          <Col> {myGymDetails.gymType}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Joined Date : </Col>
                            <Col> {myGymDetails.createdAt?.split("T")[0]}</Col>
                          </Row>
                        }
                      </ListGroup.Item>

                      <ListGroup.Item>
                        <Row>
                          <Col> Contact Number: </Col>
                          <Col> {myGymDetails.primaryContact}</Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
                <Col md={5}>
                  <Card>
                    <ListGroup>
                      <ListGroup.Item>
                        <Row>
                          <Col> Alternate Number : </Col>
                          <Col> {myGymDetails.secondaryContacts}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Pincode : </Col>
                          <Col> {myGymDetails.pincode}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Address : </Col>
                            <Col> {myGymDetails.address}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> subscriptionType : </Col>
                            <Col> {myGymDetails.subscriptionType}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      {/* <ListGroup.Item>
                        {
                          <Row>
                            <Col> Due Amount : </Col>
                            <Col>
                              {" "}
                              {myGymDetails.dueAmount}{" "}
                              <a
                                style={{
                                  color: "red",
                                  textDecoration: "none",
                                }}
                                href=""
                              >
                                click to <b>Pay</b>
                              </a>
                            </Col>
                          </Row>
                        }
                      </ListGroup.Item> */}
                    </ListGroup>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default GymProfileScreen;
