//import React from "react";
import { Row } from "react-bootstrap";

const HomeScreen = () => {
  return <Row>Home Screen</Row>;
  //   const { data: products, isLoading, error } = useGetProductsQuery();

  //   return (
  //     <>
  //       {isLoading ? (
  //         <Loader />
  //       ) : error ? (
  //         <Message variant="danger">
  //           {error?.data?.message || error.error}
  //         </Message>
  //       ) : (
  //         <>
  //           <Row>
  //             {products.map((product) => (
  //               <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
  //                 <Product product={product} />
  //               </Col>
  //             ))}
  //           </Row>
  //         </>
  //       )}
  //     </>
  //   );
  // };
  return <>Welcome</>;
};

export default HomeScreen;
