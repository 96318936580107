import { Button, Form } from "react-bootstrap";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import { useUpdateAttendanceMutation } from "../slices/attendanceSlice";
import welcomeMessage from "../assets/audios/messageTone.mp3";
import errorTone from "../assets/audios/error_tone.mp3";
import ToastMessage from "../components/ToastMessage";
import { setCredentials } from "../slices/authSlice";
import ProfileImage from "../components/ProfileImage";

const LaunchScreen = () => {
  const [userId, setUserId] = useState();

  const [updateAttendance, { isLoading }] = useUpdateAttendanceMutation();

  const { userInfo } = useSelector((state) => state.auth);

  const [isToggled, setIsToggled] = useState(userInfo.audioOn);
  const dispatch = useDispatch();
  const handleToggle = () => {
    setIsToggled(!isToggled);
    dispatch(setCredentials({ ...userInfo, audioOn: !isToggled }));
  };

  const [toastMessage, setToastMessage] = useState(false);

  const handleToastMessage = () => {
    setToastMessage(!toastMessage);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!userInfo) toast.error("Please login to use the application");
    if (userId < 1 || userId > 999999 || userId.startsWith("0"))
      toast.error("Please enter valid user Id");
    else {
      try {
        const res = await updateAttendance({
          payLoad: {
            userId,
          },
          token: userInfo.token,
        }).unwrap();
        // dispatch(setCredentials({ ...res }));
        // navigate(redirect);
        // navigate("/");
        if (res.subsAlreadyExpired) {
          toast.error(() => (
            <div style={{ backgroundColor: "red" }}>
              <audio
                src={errorTone}
                type="audio/mp3"
                autoPlay={isToggled}
              ></audio>
              <ProfileImage imageId={userInfo?.gymId._id + "" + res?.userId} />{" "}
              <br></br>
              <label style={{ color: "white" }}>
                Subscription EXPIRED on : {res?.expiresOn?.split("T")[0]}
              </label>{" "}
              <br></br>
            </div>
          ));
        } else {
          toast.success(() => (
            <div>
              <audio
                src={welcomeMessage}
                type="audio/mp3"
                autoPlay={isToggled}
              ></audio>{" "}
              <ProfileImage imageId={userInfo.gymId._id + "" + res?.userId} />{" "}
              <br />
              {res?.message} {res?.userName}
              <br />
              Valid Till: {res?.expiresOn?.split("T")[0]}
              <br />
              Expires in :{" "}
              {Math.ceil(
                (new Date(res?.expiresOn) - new Date()) / (1000 * 60 * 60 * 24)
              )}{" "}
              days
            </div>
          ));
        }
        setUserId("");
      } catch (err) {
        err.status === "PARSING_ERROR" || err.status === 400
          ? toast.error(() => (
              <div>
                <audio
                  src={errorTone}
                  type="audio/mp3"
                  autoPlay={isToggled}
                ></audio>
                <br />
                Please enter valid user ID
              </div>
            ))
          : toast.error(err.error);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "40vh",
      }}
    >
      <FormContainer>
        <Form onSubmit={submitHandler}>
          <Form.Group className="my-2" controlId="userId">
            <Form.Label id="userId">
              Enter user ID to mark attendance
            </Form.Label>
            <Form.Control
              type="text"
              pattern="[0-9]*" // Allow only digits
              placeholder="user Id"
              autoComplete="off"
              autoFocus
              value={userId || ""}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                setUserId(value);
              }}
            />
          </Form.Group>

          <Button type="submit" variant="primary">
            Submit
          </Button>
          {isLoading && <Loader />}
        </Form>
      </FormContainer>
      <div style={{ alignItems: "center", justifyContent: "center" }}>
        Audio
        <>
          <Form.Check
            type="switch"
            id="custom-switch"
            onChange={handleToggle}
          ></Form.Check>{" "}
          <>{isToggled ? "On" : "Off"}</>
        </>
      </div>{" "}
      {/* <button onClick={handleToastMessage}>
        enableToast{" "}
        <ToastMessage
          show={toastMessage}
          setToast={(e) => setToastMessage(false)}
          position="middle-center"
          delay={3000}
          autohide={true}
          bg="warning"
          animation={true}
          header="Testing header"
          body="Testing body"
        ></ToastMessage>
      </button> */}
    </div>
  );
};

export default LaunchScreen;
