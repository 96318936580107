import React, { useEffect, useState } from "react";
import { Row, Col, ListGroup, Card, Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";

import UserEditScreen from "./UserEditScreen";
import { useGetUserQuery } from "../slices/usersSlice";
import Loader from "../components/Loader";
import RenewalScreen from "./RenewalScreen";
import PaymentScreen from "./PaymentScreen";
import UpdateExpiryScreen from "./UpdateExpiryScreen";
import ProfileImage from "../components/ProfileImage";
import { useUploadPhotoMutation } from "../slices/usersSlice";

const UserProfileScreen = () => {
  const { id: userId } = useParams();
  const { userInfo } = useSelector((state) => state.auth);
  const [imageError, setImageError] = useState(false);
  const [file, setFile] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileInvalidError, setFileInvalidError] = useState(false);

  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate(userInfo.lastVisitedPage);
  };

  const {
    data: user,
    isLoading,
    refetch,
  } = useGetUserQuery({ payLoad: userId, token: userInfo.token });

  const [uploadPhoto, { isLoading: isPhotoUploading }] =
    useUploadPhotoMutation();

  const [editable, setEditable] = useState(false);
  const [renewal, setRenewal] = useState(false);
  const [payment, setPayment] = useState(false);
  const [editExpiryDate, setEditExpiryDate] = useState(false);

  const handleEditStatus = () => {
    setEditable(false);
  };

  const handleRenewalStatus = () => {
    setRenewal(false);
  };

  const handlePaymentStatus = () => {
    setPayment(false);
  };

  const handleEditExpiryDateStatus = () => {
    setEditExpiryDate(false);
  };

  useEffect(() => {
    refetch();
  }, [refetch, editable, renewal, payment, editExpiryDate]);

  const compressImage = async (selectedImage) => {
    const file = selectedImage;
    if (file) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 280,
          useWebWorker: true,
          initialQuality: 1,
        };

        console.log("Un-Compressed file:", file);
        const compressedFile = await imageCompression(file, options);
        // setFile(compressedFile);
        console.log("first Compressed file:", compressedFile);
        const secondOptions = {
          maxSizeMB: 1, // maximum size in MB
          useWebWorker: true, // use web worker for performance
          initialQuality: 0.9, // initial quality setting
        };

        // Second compression step
        const secondCompressedFile = await imageCompression(
          compressedFile,
          secondOptions
        );
        console.log("Second Compressed file:", secondCompressedFile);
        setFile(secondCompressedFile);

        // // You can now upload the compressed file to your server
        // uploadImage(compressedFile);
      } catch (error) {
        console.error("Error processing image:", error);
      }
    }
  };

  const handleFileChange = async (e) => {
    const selectedFile = e?.target?.files[0];
    const dotIndex = selectedFile?.name?.lastIndexOf(".");
    const fileExtension = selectedFile?.name?.slice(dotIndex + 1).toLowerCase();
    let errorPresent = false;
    if (
      !(
        fileExtension === "jpeg" ||
        fileExtension === "jpg" ||
        fileExtension === "png" ||
        fileExtension === "tiff" ||
        fileExtension === "svg"
      )
    ) {
      errorPresent = true;
      setFileInvalidError(true);
      setFile(null);
    } else if (selectedFile?.size / 1000 > 5120) {
      setFileSizeError(true);
      setFile(null);
    } else if (!errorPresent && selectedFile?.size / 1000 <= 5120) {
      setFileSizeError(false);
      setFileInvalidError(false);
      await compressImage(selectedFile);
      // setFile(selectedFile);
    }
  };

  const handleSubmit = async () => {
    try {
      if (file) {
        let formData = new FormData();
        formData.append("image", file);
        formData.append(
          "imageKey",
          `${userInfo?.gymId?._id}${user?._id?.toString().substring(4)}`
        );
        const imageUploadRes = await uploadPhoto({
          token: userInfo.token,
          payload: formData,
        }).unwrap();

        if (imageUploadRes) {
          toast.success(() => (
            <div>
              "Photo uploaded Successfully, Please refresh the page"
              <br />
            </div>
          ));
        }
        setFile(null);
      }
    } catch (e) {
      console.log(e);
      toast.error("Photo upload failed");
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {editable ? (
            <UserEditScreen
              user={user}
              editStatus={handleEditStatus}
            ></UserEditScreen>
          ) : renewal ? (
            <RenewalScreen
              user={user}
              renewalStatus={handleRenewalStatus}
            ></RenewalScreen>
          ) : payment ? (
            <PaymentScreen
              user={user}
              paymentStatus={handlePaymentStatus}
            ></PaymentScreen>
          ) : editExpiryDate ? (
            <UpdateExpiryScreen
              user={user}
              editExpiryDate={handleEditExpiryDateStatus}
            ></UpdateExpiryScreen>
          ) : (
            <>
              {" "}
              <Button
                style={{ backgroundColor: "grey" }}
                onClick={handleBackButton}
              >
                Back
              </Button>
              {isPhotoUploading ? (
                <Loader />
              ) : (
                <ProfileImage
                  imageId={
                    userInfo.gymId._id + "" + user._id?.toString().substring(4)
                  }
                  setImageError={setImageError}
                />
              )}
              {imageError ? (
                <>
                  <Form.Group as={Row} className="my-2" controlId="photo">
                    <Form.Label column="true" sm="3">
                      Photo not available :
                    </Form.Label>
                    <Col sm="6">
                      <input type="file" onChange={handleFileChange} />
                      {fileSizeError && (
                        <label style={{ color: "red" }}>
                          Image size should be less than 5 MB
                        </label>
                      )}
                      {fileInvalidError && (
                        <label style={{ color: "red" }}>
                          Only jpeg, jpg, png, tiff and svg formats are
                          supported
                        </label>
                      )}
                    </Col>
                  </Form.Group>
                  {file && (
                    <>
                      <Row column="true" sm="6">
                        <Col sm="4"></Col>
                        <Col sm="5">
                          <Button
                            type="submit"
                            variant="primary"
                            onClick={handleSubmit}
                          >
                            {" "}
                            Submit Image
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              ) : (
                <>
                  {" "}
                  <Form.Group as={Row} className="my-2" controlId="photo">
                    <Form.Label column="true" sm="3">
                      Want to change Photo ?
                    </Form.Label>
                    <Col sm="6">
                      <input type="file" onChange={handleFileChange} />
                      {fileSizeError && (
                        <label style={{ color: "red" }}>
                          Image size should be less than 5 MB
                        </label>
                      )}
                      {fileInvalidError && (
                        <label style={{ color: "red" }}>
                          Only jpeg, jpg, png, tiff and svg formats are
                          supported
                        </label>
                      )}
                    </Col>
                  </Form.Group>
                  {file && (
                    <>
                      <Row column="true" sm="6">
                        <Col sm="4"></Col>
                        <Col sm="5">
                          <Button
                            type="submit"
                            variant="primary"
                            onClick={handleSubmit}
                          >
                            {" "}
                            Submit Image
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
              <Row style={{ margin: "0.5%" }}>
                <Button
                  className=" marginPointOne sixteenWidth buttonLoveBlue numbersButtonAssociateProfile"
                  onClick={(e) => setRenewal(true)}
                >
                  Renew
                </Button>

                <Button
                  className=" marginPointOne sixteenWidth buttonTeal numbersButtonAssociateProfile"
                  onClick={(e) => setPayment(true)}
                >
                  Payment
                </Button>
                {userInfo.role === "owner" ||
                userInfo.role === "Super Admin" ? (
                  <>
                    <Button
                      className=" marginPointOne sixteenWidth buttonReddish numbersButtonAssociateProfile"
                      onClick={(e) => setEditExpiryDate(true)}
                    >
                      Update Expiry Date
                    </Button>{" "}
                  </>
                ) : (
                  ""
                )}

                <Button
                  className=" marginPointOne sixteenWidth buttonMaroon numbersButtonAssociateProfile"
                  onClick={(e) => setEditable(true)}
                >
                  Update Profile
                </Button>
              </Row>
              <Row>
                <Col md={5}>
                  <Card>
                    <ListGroup>
                      <ListGroup.Item>
                        <Row>
                          <Col> Name : </Col>
                          <Col> {user.firstName + " " + user.lastName}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> ID : </Col>
                          <Col> {user._id?.toString().substring(4)}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Gender : </Col>
                          <Col> {user.gender}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Subscription Type : </Col>
                          <Col> {user.subscriptionType}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> PT Enabled : </Col>
                          <Col> {user.ptEnabled ? "Yes" : "No"}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Gym Goal : </Col>
                          <Col> {user.gymGoal.join(" , ")}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Subscription Amount : </Col>
                            <Col> {user.subscriptionAmount}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Paid Amount : </Col>
                            <Col> {user.paidAmount}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row
                            style={{
                              color: user.pendingAmount > 0 ? "red" : "",
                            }}
                          >
                            <Col> Pending Amount : </Col>
                            <Col> {user.pendingAmount}</Col>
                          </Row>
                        }
                      </ListGroup.Item>{" "}
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Amount Paid Date : </Col>
                            <Col>
                              {" "}
                              {user.lastPaidDate
                                ? user.lastPaidDate?.split("T")[0]
                                : ""}
                            </Col>
                          </Row>
                        }
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
                <Col md={5}>
                  <Card>
                    <ListGroup>
                      <ListGroup.Item>
                        <Row>
                          <Col> Date Of Birth : </Col>
                          <Col> {user.dateOfBirth?.split("T")[0]}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Joined Date : </Col>
                            <Col>
                              {" "}
                              {user.joinedDate
                                ? user.joinedDate?.split("T")[0]
                                : ""}
                            </Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Renewed Date : </Col>
                            <Col>
                              {" "}
                              {user.renewedDate
                                ? user.renewedDate?.split("T")[0]
                                : ""}
                            </Col>
                          </Row>
                        }
                      </ListGroup.Item>{" "}
                      <ListGroup.Item>
                        <Row>
                          <Col> Subs Expiry Date : </Col>
                          <Col>
                            {" "}
                            {user.subscriptionExpiryDate
                              ? user.subscriptionExpiryDate?.split("T")[0]
                              : ""}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Aadhar Number : </Col>
                          <Col> {user.aadharNumber}</Col>
                        </Row>
                      </ListGroup.Item>{" "}
                      <ListGroup.Item>
                        <Row>
                          <Col> Height in cm : </Col>
                          <Col> {user.heigthInCm}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Weight in kg : </Col>
                          <Col> {user.weightInKg}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Mobile Number : </Col>
                          <Col> {user.mobileNumber}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Email : </Col>
                          <Col> {user.email}</Col>
                        </Row>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {
                          <Row>
                            <Col> Address : </Col>
                            <Col> {user.address}</Col>
                          </Row>
                        }
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <Row>
                          <Col> Active Status : </Col>
                          <Col> {user.isActive ? "Active" : "Not Active"}</Col>
                        </Row>
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default UserProfileScreen;
