import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Table, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useGetPaymentsQuery } from "../slices/paymentSlice";
import { getCurrentDate, getCurrentTime } from "../utils/getCurrentDate";
import { useGetRenewalsQuery } from "../slices/renewalSlice";
import { useGetUserQuery } from "../slices/usersSlice";
import { setCredentials } from "../slices/authSlice";

const UserPaymentsScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { id: userId } = useParams();
  const [startDate, setStartDate] = useState(userInfo.paymentsStartDate);

  const [endDate, setEndDate] = useState(userInfo.paymentsEndDate);
  // const [startDate, setStartDate] = useState(
  //   new Date("2024-01-13").toISOString().split("T")[0]
  // );

  // const [endDate, setEndDate] = useState(
  //   new Date("2024-01-13").toISOString().split("T")[0]
  // );

  const [getApiQuery, setGetApiQuery] = useState(
    `?gymId=${userInfo.gymId._id}&userId=${userId}&paidDate[gte]=${startDate}T00:00:00.000Z&paidDate[lte]=${endDate}T23:59:59.000Z`
  );

  const [renewalQuery, setRenewalQuery] = useState(
    `?gymId=${userInfo.gymId._id}&userId=${userId}&renewedDate[gte]=${startDate}T00:00:00.000Z&renewedDate[lte]=${endDate}T23:59:59.000Z`
  );

  const dispatch = useDispatch();

  const {
    data: payments,
    isLoading: paymentsLoading,
    error: paymentsError,
    refetch: paymentsRefetch,
  } = useGetPaymentsQuery({
    query: getApiQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const {
    data: renewals,
    isLoading: renewalsLoading,

    refetch: renewalsRefetch,
  } = useGetRenewalsQuery({
    query: renewalQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const {
    data: user,
    isLoading: usersLoading,

    refetch: usersRefetch,
  } = useGetUserQuery({
    payLoad: userId,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const [paymentsToList, setPaymentsToList] = useState(
    paymentsLoading ? [] : payments ? payments : []
  );
  const [allPayments, setAllPayments] = useState(
    paymentsLoading ? [] : payments ? payments : []
  );
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [renewedUsers, setRenewedUsers] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [pendingPayments, setPendingPayments] = useState();
  const [pendingPaymentsSelected, setPendingPaymentsSelected] = useState(false);

  // let totalAmount = 0;
  // paymentsToList.map((pl) => {
  //   return (totalAmount = totalAmount + pl.paidAmount);
  // });

  const handleGetPayments = async () => {
    if (startDate > getCurrentDate())
      toast.error("Start date can not be greater than today");
    else if (startDate > endDate)
      toast.error("Start date can not be greater than End date");
    else {
      if (endDate?.split("T")[0] > getCurrentDate())
        setEndDate(getCurrentDate() + getCurrentTime());
      // setEndDate(getCurrentDate() + getCurrentTime());
      setGetApiQuery(
        `?gymId=${userInfo.gymId._id}&userId=${userId}&paidDate[gte]=${startDate}T00:00:00.000Z&paidDate[lte]=${endDate}T23:59:59.000Z`
      );
      setRenewalQuery(
        `?gymId=${userInfo.gymId._id}&userId=${userId}&renewedDate[gte]=${startDate}T00:00:00.000Z&renewedDate[lte]=${endDate}T23:59:59.000Z`
      );
      dispatch(
        setCredentials({
          ...userInfo,
          paymentsStartDate: startDate,
          paymentsEndDate: endDate,
        })
      );
    }
  };

  const [initApiRequestAtIntervals, setInitApiRequestAtIntervals] = useState(1);
  setInterval(() => {
    setInitApiRequestAtIntervals(Date.now());
  }, 1200000);

  useEffect(() => {
    paymentsRefetch();
  }, [paymentsRefetch, initApiRequestAtIntervals]);

  useEffect(() => {
    renewalsRefetch();
    paymentsRefetch();
    usersRefetch();
    const allPays = paymentsLoading ? [] : payments;
    setPaymentsToList(allPays);
    setAllPayments(allPays);

    const allSubs = renewalsLoading ? [] : renewals;
    setAllSubscriptions(allSubs);

    const reneUsers = renewalsLoading
      ? []
      : renewals
      ? renewals.filter((pl) => pl.compositeId.joinedDate < pl.renewedDate)
      : [];
    setRenewedUsers(reneUsers);
    const newUsrs = renewalsLoading
      ? []
      : renewals
      ? renewals.filter((pl) => pl.compositeId.joinedDate === pl.renewedDate)
      : [];
    setNewUsers(newUsrs);

    const pendingPaymentUsers = usersLoading ? {} : user;
    setPendingPayments(pendingPaymentUsers);
  }, [payments, renewals, user]);

  const handleFilterSelecttion = (val) => {
    setPaymentsToList(val);
    setPendingPaymentsSelected(false);
  };

  const handlePendingPaymentSelection = (val) => {
    setPaymentsToList(val);
    setPendingPaymentsSelected(true);
  };
  const navigate = useNavigate();
  const handleBackButton = () => {
    navigate("/payments");
  };

  return (
    <>
      <div style={{ display: "flex", paddingLeft: "0.5%" }}>
        <Form.Group className="my-1">
          <Button
            onClick={handleBackButton}
            style={{ backgroundColor: "grey" }}
          >
            Back
          </Button>
        </Form.Group>

        <h4
          style={{
            width: "90%",
            textAlign: "center",
          }}
        >
          <Link to={`/users/${user?.userId}`}>
            {usersLoading ? "" : user.firstName + " " + user.lastName}
          </Link>
        </h4>
      </div>
      <Row
        style={{
          width: "90%",
          margin: "auto",
          paddingBottom: "1.5%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-23"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-23"
              value={endDate?.split("T")[0]}
              onChange={(e) => setEndDate(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col xs={12} md="auto" sm={6} className="d-flex justify-content-center">
          <Form.Group as={Row} className="my-1">
            <Button onClick={handleGetPayments}>Get Payments</Button>
          </Form.Group>
        </Col>
      </Row>
      <Row
        className="mb-3"
        style={{
          width: "95%",
          margin: "auto",
          paddingBottom: "1.5%",
          justifyContent: "center",
        }}
      >
        <Button
          className="marginPointEight sixteenWidth buttonGreen numbersButtonDashboard"
          onClick={() => handleFilterSelecttion(allSubscriptions)}
        >
          Subcriptions{" "}
          <strong>
            {allSubscriptions && allSubscriptions?.length > 0
              ? allSubscriptions?.length === 1
                ? allSubscriptions[0].subscriptionAmount
                : allSubscriptions?.reduce((accumulator, obj) => {
                    return accumulator + obj.subscriptionAmount;
                  }, 0)
              : 0}
          </strong>
          <Badge>{allSubscriptions?.length}</Badge>
        </Button>

        <Button
          className=" marginPointEight sixteenWidth buttonLoveBlue numbersButtonDashboard"
          onClick={() => handleFilterSelecttion(allPayments)}
        >
          Collected{" "}
          <strong>
            {allPayments && allPayments?.length > 0
              ? allPayments?.length === 1
                ? allPayments[0].paidAmount
                : allPayments?.reduce((accumulator, obj) => {
                    return accumulator + obj.paidAmount;
                  }, 0)
              : 0}
            {/* {allPayments?.reduce((accumulator, obj) => {
              return accumulator + obj.paidAmount;
            }, 0)} */}
          </strong>
          <Badge>{allPayments?.length}</Badge>
        </Button>
        <Button
          style={{ backgroundColor: user?.pendingAmount > 0 ? "red" : "" }}
          className=" marginPointEight sixteenWidth buttonPeach numbersButtonDashboard"
        >
          Pending <strong>{user?.pendingAmount}</strong>
          <Badge>{user?.pendingAmount > 0 ? 1 : 0}</Badge>
        </Button>
      </Row>

      {paymentsLoading && renewalsLoading ? (
        <Loader />
      ) : paymentsError ? (
        <Message>{paymentsError?.data?.error}</Message>
      ) : (
        <Table striped bordered hover responsive="sm">
          <thead>
            <tr>
              <th>Subs Amount</th>
              <th>Renewed Date</th>
              <th>Paid Amount</th>
              <th>Paid Date</th>
              <th>TransactionID</th>
              {pendingPaymentsSelected ? <th>Pending</th> : ""}
            </tr>
          </thead>
          {paymentsToList && paymentsToList?.length > 0 ? (
            <tbody>
              {paymentsToList.map((user) => (
                <tr key={user.userId + "" + user.createdAt}>
                  <td>{user.subscriptionAmount}</td>
                  <td>{user.renewedDate?.split("T")[0]}</td>
                  <td>{user.paidAmount}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {user.paidDate
                      ? user.paidDate?.split("T")[0]
                      : user.lastPaidDate?.split("T")[0]}
                  </td>
                  <td>{user.transactionId}</td>
                  {pendingPaymentsSelected ? (
                    <td
                      style={{
                        color: user.pendingAmount > 0 ? "red" : "",
                      }}
                    >
                      {user.pendingAmount}
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
            </tbody>
          ) : (
            <Message>No Payments Available for the selected Period</Message>
          )}
        </Table>
      )}
    </>
  );
};

export default UserPaymentsScreen;
